import Carousel from "react-material-ui-carousel";
import { useEffect, useState } from "react";
import "pages/Presentation/components/carousel/carousel.min.css";
import home_content from "content/Home.json";

function ResponsiveCarousel() {
  const [maxHeight, setMaxHeight] = useState(650); // Set a maximum height of 650 pixels
  const calculateCarouselHeight = () => {
    // Calculate the maximum height among all slides
    const slideElements = document.querySelectorAll(".home__image");
    let maxHeight = 0;

    slideElements.forEach((slide) => {
      const slideHeight = slide.offsetHeight;
      if (slideHeight > maxHeight) {
        maxHeight = slideHeight;
      }
    });

    // Ensure the maximum height doesn't exceed 650 pixels
    setMaxHeight(Math.min(maxHeight, 650));
  };

  useEffect(() => {
    // Calculate the carousel height on initial load or window refresh
    window.onload = () => {
      calculateCarouselHeight();
    };

    // Attach a resize event listener to recalculate height on window resize
    window.addEventListener("resize", calculateCarouselHeight);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", calculateCarouselHeight);
    };
  }, []); // Empty dependency array ensures it runs after mount

  return (
    <div style={{ height: `${maxHeight}px` }}>
      <Carousel
        autoPlay={true}
        indicators={false}
        className="home__carousel"
        navButtonsAlwaysVisible={false}
        sx={{ zIndex: -1 }}
      >
        {home_content.carousel.map((content, index) => (
          <div key={index} className="carousel-slide">
            <img
              style={{ width: "100%", maxHeight: "700px", objectFit: "cover" }}
              src={process.env.PUBLIC_URL + content.url}
              alt={content.alt}
              className="home__image"
            />
            <div className="tagline">
              <p>{content.tagline}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ResponsiveCarousel;
