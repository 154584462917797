// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import books_content from "content/Books.json";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Read all over the globe
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {books_content.Books_show_case.map((book, index) => (
            <Grid item xs={12} sm={6} lg={3} key={index}>
              <TransparentBlogCard
                image={book.url}
                title={book.title}
                description={book.description}
                action={{
                  type: "internal",
                  route: book.link || " ",
                  color: "info",
                  label: "Get it here",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
