// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Instagram from "@mui/icons-material/Instagram";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Mudacumura Publishing House",
    image: "/images/logo.png",
    route: "/",
  },
  socials: [
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/mudacumurapublishinghouse/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/mudacumurapubli",
    },
    {
      icon: <FacebookIcon />,
      link: "https://web.facebook.com/mudacumurapublishing/?_rdc=1&_rdr",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCikHnACVIiXs_6rTY2Cy-6w",
    },
  ],
  menus: [
    {
      name: "Go to",
      items: [
        { name: "books", route: "/pages/landing-pages/books" },
        { name: "animations", route: "/pages/landing-pages/animations" },
        { name: "authors & illustrators", route: "/pages/landing-pages/author" },
        { name: "about us", route: "/pages/landing-pages/about-us" },
        { name: "contact us", route: "/pages/landing-pages/contact-us" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Mudacumura Publishing House.
    </MKTypography>
  ),
};
