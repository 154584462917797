// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import authors_content from "content/Authors.json";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="error"
      position="relative"
      p={2}
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              {authors_content.title}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {authors_content.authors.map((author, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <MKBox mb={{ xs: 1, lg: 0 }}>
                <HorizontalTeamCard
                  image={author.url}
                  name={author.names}
                  position={{ color: "info", label: author.Position }}
                  description={author.description}
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
