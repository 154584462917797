// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import aboutus_content from "content/AboutUs.json";

function Information() {
  const paragraphs = [];
  aboutus_content.rst_abt_paras.forEach((element) => {
    paragraphs.push(<DefaultInfoCard small="body2" description={element.paragraph} />);
  });
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard title="OUR VISION" description={aboutus_content.our_vision} />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard title="OUR MISSION" description={aboutus_content.our_mission} />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard title="OUR STRATEGY" />
                  <DefaultInfoCard
                    small="body2"
                    title="Values"
                    description={aboutus_content.values}
                  />
                  <DefaultInfoCard
                    small="body2"
                    title="Business Goals And Objectives"
                    description={aboutus_content.business_goals}
                  />
                  <DefaultInfoCard
                    small="body2"
                    title="Business Strategy"
                    description={aboutus_content.business_strategy}
                  />
                  <DefaultInfoCard
                    small="body2"
                    title="Economic Intent"
                    description={aboutus_content.economic_intent}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <DefaultInfoCard
              small="body2"
              title="About us"
              description={aboutus_content.abt_top_para}
            />
            {paragraphs}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
