// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import Books from "layouts/pages/landing-pages/books";
import Animations from "layouts/pages/landing-pages/animations";

const routes = [
  {
    name: "books",
    route: "/pages/landing-pages/books",
    component: <Books />,
  },
  {
    name: "Animations",
    route: "/pages/landing-pages/animations",
    component: <Animations />,
  },
  {
    name: "authors & illustrators",
    route: "/pages/landing-pages/author",
    component: <Author />,
  },
  {
    name: "about us",
    route: "/pages/landing-pages/about-us",
    component: <AboutUs />,
  },
  {
    name: "contact us",
    route: "/pages/landing-pages/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
