// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

import home_content from "content/Home.json";

function Counters() {
  const cloudDownloadIconStyles = {
    color: "red",
    fontSize: "25px",
  };
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={home_content.counter_one_number}
              suffix="+"
              title={home_content.counter_one_title}
              description={home_content.counter_one_description}
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <a
              href={home_content.catalogLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                textDecoration: "none",
              }}
            >
              <MKTypography fontWeight="bold" mb={0.5}>
                Download Catalog Here
              </MKTypography>
              <CloudDownloadIcon style={cloudDownloadIconStyles} />
            </a>
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={home_content.counter_three_number}
              suffix="+"
              title={home_content.counter_three_title}
              description={home_content.counter_three_description}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
