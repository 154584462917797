// react-router-dom components
import React from "react";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import home_content from "content/Home.json";

function Pages() {
  const renderData = home_content.Books_show_case.map(({ book_image, name }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <Link to="">
        <ExampleCard image={book_image} name={name} display="grid" minHeight="auto" />
      </Link>
    </Grid>
  ));

  const [videoOpts, setVideoOpts] = React.useState({
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  });

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setVideoOpts({
          ...videoOpts,
          width: "300",
          height: "200",
        });
      } else {
        setVideoOpts({
          ...videoOpts,
          width: "640",
          height: "390",
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <div
            style={{
              width: "300px",
              height: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <YouTube videoId={home_content.videoId} opts={videoOpts} onReady={onReady} />
          </div>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
